// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  // apiUrl:"http://localhost:9000",
  // publicUrl:'http://localhost:9000',
  // adminUrl:'http://localhost:9000',
  // socketUrl:"http://localhost:9000",
  apiUrl:"https://api.trd.co.id",
  publicUrl:'https://api.trd.co.id',
  adminUrl:'https://api.trd.co.id',
  socketUrl:"https://api.trd.co.id",
  blogUrl:"https://dev.trd.co.id",
  baseUrl:"https://trd.co.id",
  limitData:25,
  ppn:11
};