import {Component, OnInit} from "@angular/core";
import {icon, latLng, Layer, marker, tileLayer} from "leaflet";
import {DataService} from "src/app/services/data.service";
import {EventService} from "src/app/services/event.service";
const globalDate = new Date();

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  typeActive: string = "harian";
  startDate: any = new Date(globalDate.getFullYear(), globalDate.getMonth(), 1);
  endDate: any = new Date(
    globalDate.getFullYear(),
    globalDate.getMonth() + 1,
    0
  );
  data: any = [];
  constructor(
    private dataService: DataService,
    private eventService: EventService
  ) {
    this.eventService.periodeData.subscribe(async item => {
      if (item) {
        this.getList({
          cid: item.customer,
          jenis: item.typeActive,
          startDate: item.startDate,
          endDate: item.endDate,
        });
      }
    });
  }

  async ngOnInit() {}

  // Open Street Map definitions
  LAYER_OSM = tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    maxZoom: 18,
    attribution: "Open Street Map",
  });

  // Values to bind to Leaflet Directive
  options = {
    layers: [this.LAYER_OSM],
    zoom: 5,
    center: latLng(-0.813, 117.62),
  };

  markers: Layer[] = [];

  addMarker(data?: any) {
    const icn = {
      icon: icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: "assets/images/logo/logo_profile_white.png",
        iconRetinaUrl: "assets/leaflet/marker-icon-2x.png",
        shadowUrl: "assets/leaflet/marker-shadow.png",
      }),
    };

    for (const mm of data) {
      const newMarker = marker(latLng(mm.latitude, mm.longitude), {
        icon: icon({
          iconSize: [41, 41],
          iconAnchor: [41, 41],
          iconUrl: "assets/images/pin.png",
          tooltipAnchor: [0, -41],
        }),
        riseOnHover: true,
      }).bindTooltip(
        `
      <table border="0">
      <tr>
          <td>Kota</td>
          <td>&nbsp;:&nbsp;</td>
          <td>${mm.namakota}</td>
      </tr>
      <tr>
          <td>Awb</td>
          <td>&nbsp;:&nbsp;</td>
          <td>${mm.Awb}</td>
      </tr>
      <tr>
          <td>Total</td>
          <td>&nbsp;:&nbsp;</td>
          <td>${this.numberWithCommas(mm.Amount)}</td>
      </tr>
      </table>
      `,
        {
          permanent: false,
          opacity: 1,
          direction: "top",
        }
      );
      this.markers.push(newMarker);
    }
  }

  async getList(params: any) {
    try {
      let res:any;
      if(params.cid){
        res = await this.dataService.customerAreaByMap(
          params.cid,
          params.jenis,
          params.startDate,
          params.endDate
        );
      }else{
        res = await this.dataService.dataAreaByMap(
          params.jenis,
          params.startDate,
          params.endDate
        );
      }
      this.addMarker(res.data);
      this.data = res.data;
    } catch (error) {
      console.log(error);
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  
}
