import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import {
  NgbNavModule,
  NgbAccordionModule,
  NgbTooltipModule,
  NgbModule,
  NgbPagination,
  NgbPaginationModule,
  NgbDatepickerModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { ExtrapagesModule } from "./views/extrapages/extrapages.module";
import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ErrorInterceptor } from "./helpers/error.interceptor";
import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { ToastNotificationsModule } from "ngx-toast-notifications";
import { UIModule } from "./shared/ui/ui.module";
import { FormsModule } from "@angular/forms";
import { NgxMaskModule } from "ngx-mask";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { SafeUrlPipe } from "./helpers/safeurl";
import { DateAgoPipe } from "./helpers/date-ago.pipe";
import { WidgetModule } from "./shared/widget/widget.module";
import { QRCodeModule } from "angularx-qrcode";
import { NgxBarcodeModule } from "ngx-barcode";
import { LightboxModule } from "ngx-lightbox";
import { ModalQuestionsComponent } from "./modals/modal-questions/modal-questions.component";
import { NgApexchartsModule } from "ng-apexcharts";

 
export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
@NgModule({
  declarations: [AppComponent, SafeUrlPipe, DateAgoPipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LayoutsModule,
    ExtrapagesModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbPaginationModule,
    NgApexchartsModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    ScrollToModule.forRoot(),
    ToastNotificationsModule.forRoot({
      duration: 2000,
      type: "danger",
      position: "top-right",
    }),
    NgbModule,
    UIModule,
    FormsModule,
    PdfViewerModule,
    WidgetModule,
    QRCodeModule,
    NgxBarcodeModule,
    LightboxModule,

  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalQuestionsComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AppModule {}
