import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
const httpOptions = {
  headers: new HttpHeaders({ Accept: "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}
  public qsconvert(params: any) {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
  }
  
}
