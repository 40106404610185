import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss']
})
export class SelectCustomerComponent implements OnInit {

  dataList:any=[];
  userValue:any;
  customerAktif:any;
  @Input() CID:string;
  @Output() gantiCustomer: EventEmitter<any> = new EventEmitter();
  constructor(
    private authService:AuthenticationService
  ) { }

  ngOnInit(): void {
    this.customerAktif = localStorage.getItem('customerAktif');
    console.log('this.customerAktif', this.customerAktif);
    this.userValue = this.authService.currentUserValue;
    this.dataList=this.userValue.customerGroup
    this.pilihCustomerFirst(this.userValue.kodecustomer)
    localStorage.setItem('customerAktif',this.userValue.kodecustomer);
    
  }

  cariDataPerusahaan(CustomerKode:string){
    return this.dataList.find(i => i.CustomerKode === CustomerKode)
  }

  pilihCustomerFirst(company_id:string){
    
    console.log('Pilih customer')
 
    const data = localStorage.getItem('currentUser');
    
    let xData =JSON.parse(data);
    this.customerAktif=this.cariDataPerusahaan(company_id)
    console.log(xData)
    xData.kodecustomer=this.customerAktif.CustomerKode
    localStorage.setItem('currentUser', JSON.stringify(xData));
    this.gantiCustomer.emit(this.customerAktif)
  }

  pilihCustomer(company_id:string){
    
    console.log('Pilih customer')
 
    const data = localStorage.getItem('currentUser');
    
    let xData =JSON.parse(data);
    this.customerAktif=this.cariDataPerusahaan(company_id)
    console.log(xData)
    xData.kodecustomer=this.customerAktif.CustomerKode
    localStorage.setItem('currentUser', JSON.stringify(xData));
    this.gantiCustomer.emit(this.customerAktif)
    setTimeout(() => {
        window.location.reload();
    }, 500);
  }

}
