import { Component , OnInit} from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  user:any;
  link:any;
  constructor(
    private authenticationService: AuthenticationService
  ){}

  ngOnInit() {
    const data = localStorage.getItem('currentUser');
    if(data){
      const user = JSON.parse(data);
      console.log(user);
      this.authenticationService.setCurrentUserValue(user);
    } 
    this.authenticationService.currentUserSubject.subscribe((arg)=>{
      console.log(arg);
      
      this.user = arg;
      this.link = `https://wa.me/6281288888872?text=Halo%20saya%20%20%20${arg.nama}%20 dari %20${arg?.customerInfo?.Nama}`;
    });
  }
}
