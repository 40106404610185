
import { environment } from "src/environments/environment";
export class GlobalConstants {
    public static logoUrl: string = `assets/logo/logo-default.png`
    public static siteTitle: string = "title";
    public static x:any={
        limitData:20
    };
    public static typeHarga:any=['flat','fixed'];
    public static cariArrayData(nameKey:any,myArray:any,field:string){
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i][field].toString() === nameKey.toString()) {
                return myArray[i];
            }
        }
    }
    public static displayGreeting(){
        let day = new Date();
        let hr = day.getHours();
        let info ='';
        if (hr >= 0 && hr < 12) {
            info = 'Selamat Pagi !'
        } else if (hr == 12) {``
            info = 'Selamat Siang !'
        } else if (hr >= 12 && hr <= 17) {
            info = 'Selamat Siang !'
        } else {
            info = 'Selamat Malam !'
        }
        return info;
    }
    public static typeReport:any=['harian','bulanan','tahunan']
 
}
export function popupwindow(url, title, w, h) {
    var left = (screen.width/2)-(w/2);
    var top = (screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  }
