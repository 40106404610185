import {MenuItem} from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1000,
    label: "SMART COURIER",
    isTitle: true,
  },
  {
    id: 2000,
    label: "Internal",
    isTitle: true,
    isSuper: true,
  },
  {
    id: 2100,
    label: "Dashboard BOD",
    icon: "bx-briefcase-alt-2",
    link: "/bod",
    isSuper: true,
  },
  {
    id: 4000,
    label: "Customer",
    isTitle: true,
    isSuper: true,
  },
  {
    id: 4100,
    label: "Dashboard Customer",
    icon: "bx-user-pin",
    link: "/customer",
    // isSuper:true
  },

  {
    id: 3000,
    label: "TRACE PENGIRIMAN",
    isTitle: true,
  },
  {
    id: 3010,
    label: "BY AWB",
    icon: "bx-file-find",
    link: "/awb",
  },
  {
    id: 3020,
    label: "BY DATE",
    icon: "bx-calendar",
    link: "/awb-date",
  },
  {
    id: 3023,
    label: "BY DESTINATION",
    icon: "bx-calendar",
    link: "/awb-dest",
  },
  // {
  //   id: 3030,
  //   label: "SUMMARY",
  //   icon: "bx-bar-chart-alt-2",
  //   link: "/summary",
  // },
  {
    id: 4000,
    label: "Check Harga",
    isTitle: true,
  },
  {
    id: 4010,
    label: "Check Harga",
    icon: "bx-purchase-tag",
    link: "/check-price",
  },
  {
    id: 5000,
    label: "Customer",
    isTitle: true,
  },
  {
    id: 5010,
    label: "Invoice",
    icon: "bx-purchase-tag",
    link: "/customer-inv",
  },
];



export const MENU_ADMIN: MenuItem[] = [
  {
    id: 1000,
    label: "SMART COURIER",
    isTitle: true,
  },
  {
    id: 2000,
    label: "Internal",
    isTitle: true,
    isSuper: true,
  },
  {
    id: 2100,
    label: "Dashboard BOD",
    icon: "bx-briefcase-alt-2",
    link: "/bod",
    isSuper: true,
  },
  {
    id: 4000,
    label: "Customer",
    isTitle: true,
    isSuper: true,
  },
  {
    id: 4100,
    label: "Dashboard Customer",
    icon: "bx-user-pin",
    link: "/customer",
    // isSuper:true
  },

  {
    id: 3000,
    label: "TRACE PENGIRIMAN",
    isTitle: true,
  },
  {
    id: 3010,
    label: "BY AWB",
    icon: "bx-file-find",
    link: "/awb",
  },
  {
    id: 3020,
    label: "BY DATE",
    icon: "bx-calendar",
    link: "/awb-date",
  },
  {
    id: 3023,
    label: "BY DESTINATION",
    icon: "bx-calendar",
    link: "/awb-dest",
  },
  // {
  //   id: 3030,
  //   label: "SUMMARY",
  //   icon: "bx-bar-chart-alt-2",
  //   link: "/summary",
  // },
  {
    id: 4000,
    label: "Check Harga",
    isTitle: true,
  },
  {
    id: 4010,
    label: "Check Harga",
    icon: "bx-purchase-tag",
    link: "/check-price",
  },
  {
    id: 5000,
    label: "Customer",
    isTitle: true,
  },
  {
    id: 5010,
    label: "Invoice",
    icon: "bx-purchase-tag",
    link: "/customer-inv",
  },
  {
    id: 5000,
    label: "CMS",
    isTitle: true,
  },
  {
    id: 5010,
    label: "Blog",
    icon: "bx-purchase-tag",
    link: "/blog",
  },
];
