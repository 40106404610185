<div class="card">
    <div class="card-body">
        <div leaflet style="height: 480px;margin-bottom: 20px;" [leafletOptions]="options" [leafletLayers]="markers">
        </div>

        <table class="table table-bordered align-text-top table-borderless">
            <thead style="position: sticky;top: 0">
                <tr>
                    <th style="width: 5%;">No</th>
                    <th style="width: 50%;">Nama Kota</th>
                    <th style="width: 10%">Awb</th>
                    <th style="width: 10%" class="text-right">Tonase (Kg)</th>
                    <th style="width: 10%" class="text-right"> Total</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.namakota}}</td>
                    <td>{{item.Awb}}</td>
                    <td class="text-right">{{item.Weight | number: '1.0-0'}}</td>
                    <td class="text-right">{{item.Amount | number: '1.0-0'}}</td>
                </tr>
            </tbody>

        </table>
    </div>
</div>