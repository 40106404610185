import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { PagetitleComponent } from './pagetitle/pagetitle.component';

import { LoaderComponent } from './loader/loader.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SelectCustomerComponent } from './select-customer/select-customer.component';





@NgModule({
  declarations: [PagetitleComponent, LoaderComponent, DatepickerComponent, UploadButtonComponent, SelectCustomerComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    SimplebarAngularModule
  ],
  exports: [PagetitleComponent,LoaderComponent,DatepickerComponent,UploadButtonComponent,SelectCustomerComponent],
  

})
export class UIModule { }
