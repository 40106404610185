<div class="card mini-stats-wid">
    <div class="card-body">
        <div class="media">
            <div class="media-body">
                <p class="text-muted fw-medium">{{title}}</p>
                <h4 class="mb-0">{{value}}</h4>
            </div>

            <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                <span class="avatar-title">
          <i class="bx {{icon}} font-size-24"></i>
        </span>
            </div>
        </div>
    </div>
    <!-- end card-body -->
</div>
<!-- end card -->