import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalConstants } from 'src/app/services/global';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  userValue: any;
  noResi:any;
  global: any = {
    ...GlobalConstants.x
  }
  user;
  dataNotification: any = [];
  link:string="/";
  link_wa:any;
  constructor(@Inject(DOCUMENT) private document: any,
    private router: Router,
    // private apiService: ApiService,
    private authService: AuthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    private modal: NgbModal,
    public _cookiesService: CookieService,
    ) {

      this.authService.currentUser.subscribe((dt)=>{
        this.user = dt;
        if(this.user.iscustomer){
          this.link = "/customer";
          this.link_wa = `https://wa.me/6281288888872?text=Halo%20saya%20%20%20${dt.nama}%20 dari %20${dt?.customerInfo?.Nama}`;
        }else{
          this.link = "/bod";
          this.link_wa = `https://wa.me/6281288888872?text=Halo%20saya%20%20%20${dt.nama}%20`;
        }
      });
  }
  listLang = [
    { text: 'Indonesia', flag: 'assets/images/flags/indonesia.png', lang: 'id' },
    { text: 'English', flag: 'assets/images/flags/english.png', lang: 'en' },
  ];
  openMobileMenu: boolean;
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.userValue = this.authService.currentUserValue;
    // if (this.userValue && !this.userValue.pic_profile){
    //   this.userValue.pic_profile="assets/images/logo-default.png"
    // }
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/indonesia.png'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    this.getNotification();
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }
  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }
  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }
  /**
   * Logout the user
   */
  logout() {
    this.authService.logout()
  }
  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
  async getNotification() {
    try {
      // const res: any = await this.apiService.NOTIFICATION.bell();
      // if (res && res.success == true) {
      //   this.dataNotification = res.data;
      // }
    } catch (error) {
    }
  }

  gantiCustomer(event:any){
    console.log('ganti customer')
    console.log(event)
  }
 
}
