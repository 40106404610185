import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { AuthenticationService } from './authentication.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Accept': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }
  getStartEndDate(){
    let firstDay = moment(moment().startOf('month')).add(0, 'day').format('YYYY-MM-DD hh:mm:ss');
    let lastDay = moment(moment().endOf('month')).add(0, 'day').format('YYYY-MM-DD hh:mm:ss');
    let formatNum = moment(moment()).format('YYYYMMDD');
    return {
        fDay: firstDay,
        lDay: lastDay,
        formatNum: formatNum
    };
  }
  tracing(formdata:any) {
    return this.http
      .post<any>(`${environment.apiUrl}/tracing`, formdata, httpOptions).toPromise();
  }
  dashboard(cid:string) {
    return  this.http
      .get<any>(`${environment.apiUrl}/customer/${cid}/shipment-dashboard`, httpOptions).toPromise() 
  }
  dashboardMuatan(cid:string, jenis:string, 
    startDate?:any, 
    endDate?:any,
    avgWeight?:any, 
    actualWeight?:any) {
    return this.http.get<any>(`${environment.apiUrl}/customer/${cid}/shipment-dashboard-muatan?type=${jenis}&${this.tgl(startDate,endDate)}`, httpOptions).toPromise();
  }
  public generalOmsetPeriods(
    cid:string, 
    jenis:string, 
    startDate?:any, 
    endDate?:any
  ) {
    return this.http
      .get(`${environment.apiUrl}/customer/${cid}/shipment-dashboard-periods?type=${jenis?jenis:'harian'}&${this.tgl(startDate,endDate)}`)
      .toPromise();
  }

  
  public ByModaOmset(
    cid:string, 
    jenis:string, 
    startDate?:any, 
    endDate?:any
  ) {
    return this.http
      .get(`${environment.apiUrl}/customer/${cid}/shipment-dashboard-moda?type=${jenis}&${this.tgl(startDate,endDate)}`)
      .toPromise();
  }

  public ByAreaOmset(
    cid:string, 
    jenis:string, 
    startDate?:any, 
    endDate?:any
  ) {
    return this.http
      .get(`${environment.apiUrl}/customer/${cid}/shipment-dashboard-area?type=${jenis}&${this.tgl(startDate,endDate)}`)
      .toPromise();
  }
  tonase(query:any) {
    return this.http
      .get<any>(`${environment.apiUrl}/customer/shipment-summary?${query}`, httpOptions).toPromise();
  }
  shipmentSummary(start_date:any, end_date:any,type:any,isCustomer?:boolean,cid?:string){
    return isCustomer? this.http
      .get<any>(`${environment.apiUrl}/customer/${cid}/shipment-summary?start_date=${start_date}&end_date=${end_date}&group=${type}`, httpOptions).toPromise():
      this.http
      .get<any>(`${environment.apiUrl}/dashboard/shipment-summary?start_date=${start_date}&end_date=${end_date}&group=${type}`, httpOptions).toPromise()
      ;
  }
  shipmentDetail(start_date:any, end_date:any,pg:any, limit:any, report:any, term:any='', cid:any){
    console.log('nnnnn',end_date);
    const currentUser = this.authService.currentUserValue;
    let query = currentUser.iscustomer?`${environment.apiUrl}/customer/${cid}/shipment-detail?start_date=${start_date}&end_date=${end_date}&pg=${pg}&limit=${limit}`:`${environment.apiUrl}/dashboard/shipment-detail?start_date=${start_date}&end_date=${end_date}&pg=${pg}&limit=${limit}`;
    if(report){
      query = `${query}&report=${report}`;
    }
    if(term){
      query = `${query}&term=${term}`;
    }
    return this.http
      .get<any>(query, httpOptions).toPromise();
  }
  shipmentDetailKota(start_date:any, end_date:any,pg:any, limit:any, report:any, term:any='', cid:any){
    const currentUser = this.authService.currentUserValue;
    let query = currentUser.iscustomer?`${environment.apiUrl}/customer/${cid}/shipment-detail?isDest=true&start_date=${start_date}&end_date=${end_date}&pg=${pg}&limit=${limit}`:`${environment.apiUrl}/dashboard/shipment-detail?isDest=true&start_date=${start_date}&end_date=${end_date}&pg=${pg}&limit=${limit}`;
    if(report){
      query = `${query}&report=${report}`;
    }
    if(term){
      query = `${query}&term=${term}`;
    }
    return this.http
      .get<any>(query, httpOptions).toPromise();
  }

  cariCustomer(term:any='',pg?:any, limit?:any ){
    const currentUser = this.authService.currentUserValue;
    let query = `${environment.apiUrl}/customerlist?term=${term}&pg=${pg}&limit=${limit}` ;
    return this.http
      .get<any>(query, httpOptions).toPromise();
  }
  shipmentDetailDownload(start_date:any, end_date:any,pg:any, limit:any, report:any, cid:any){
    const currentUser = this.authService.currentUserValue;
    let url = cid ? `${environment.apiUrl}/customer/${cid}/shipment-detail?start_date=${start_date}&end_date=${end_date}&pg=${pg}&limit=${limit}&report=${report}&t=${currentUser.token}`: `${environment.apiUrl}/dashboard/shipment-detail?start_date=${start_date}&end_date=${end_date}&pg=${pg}&limit=${limit}}&report=${report}&t=${currentUser.token}`;
    return url;
  }
  carikota(formdata:any) {
    return this.http
      .post<any>(`${environment.apiUrl}/carikota`, formdata, httpOptions).toPromise();
  }
  uom() {
    return this.http
      .get<any>(`${environment.apiUrl}/uom`, httpOptions).toPromise();
  }
  cariHarga(formdata:any) {
    return this.http
      .post<any>(`${environment.apiUrl}/cariharga`, formdata, httpOptions).toPromise();
  }
  private tgl(startDate,endDate ){
    let x ='';
    return `start_date=${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}&end_date=${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`
  }

  customerAreaByMap(
    cid:string, 
    jenis:string, 
    startDate?:any, 
    endDate?:any
  ) {
    return this.http
      .get(`${environment.apiUrl}/customer/${cid}/shipment-dashboard-host?type=${jenis}&${this.tgl(startDate,endDate)}`)
      .toPromise();
  }

  dataAreaByMap(
    jenis:string, 
    startDate?:any, 
    endDate?:any
  ) {
    return this.http
      .get(`${environment.apiUrl}/dashboard/shipment-dashboard-host?type=${jenis}&${this.tgl(startDate,endDate)}`)
      .toPromise();
  }
}
