import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbPaginationModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { StatComponent } from './stat/stat.component';
import { UIModule } from '../ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMaskModule } from 'ngx-mask';
import { MapComponent } from './map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
@NgModule({
  declarations: [StatComponent, MapComponent],
  imports: [
    CommonModule,
    NgbModalModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    FormsModule,
    UIModule,
    AutocompleteLibModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgxMaskModule,
    LeafletModule,
  ],
  exports: [StatComponent,MapComponent],
})
export class WidgetModule {}
