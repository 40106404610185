<div class="dropdown d-inline-block" ngbDropdown>
  <button
    type="button"
    class="btn btn-info dropdown-toggle"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <i class="mdi mdi-wallet me-1"></i>
    <span class="d-none d-sm-inline-block" ngbDropdownToggle
      > {{ customerAktif?.CustomerKode }} -
      {{ customerAktif?.Nama }} <i class="mdi mdi-chevron-down"></i
    ></span>
  </button>
  <div
    class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
    aria-labelledby="page-header-notifications-dropdown"
    ngbDropdownMenu
  >
    <div class="p-3">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="m-0">Pilih Akun</h6>
        </div>
        <div class="col-auto">
          {{ customerAktif?.CustomerKode }} - {{ customerAktif?.Nama }}
        </div>
      </div>
    </div>
    <ngx-simplebar
      style="position: relative; height: 230px"
      data-simplebar-auto-hide="true"
    >
      <a
        ngbDropdownItem
        href="javascript: void(0);"
        class="text-reset notification-item"
        *ngFor="let item of dataList"
        (click)="pilihCustomer(item.CustomerKode)"
      >
        <div class="media">
          <div class="avatar-xs me-3" *ngIf="item.CustomerKode== 'ALL'">
            <span class="avatar-title bg-danger rounded-circle font-size-16">
              <i class="bx bx-home-circle"></i>
            </span>
          </div>
          <div class="avatar-xs me-3" *ngIf="item.CustomerKode !== 'ALL'">
            <span class="avatar-title bg-info rounded-circle font-size-16">
              <i class="bx bx-buildings"></i>
            </span>
          </div>
          <div class="media-body">
            <h6 class="mt-0 mb-1">{{ item.CustomerKode }}</h6>
            <div class="font-size-12 text-muted">
              <p class="mb-1">{{ item.Nama }}</p>
            </div>
          </div>
        </div>
      </a>
    </ngx-simplebar>
    <div class="p-2 border-top d-grid">
      <a
        class="btn btn-sm btn-link font-size-14 text-center"
        href="javascript:void(0)"
      >
        <i class="mdi mdi-arrow-right-circle me-1"></i>
        <span key="t-view-more"> Semua</span>
      </a>
    </div>
  </div>
</div>
