import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SuperadminGuard } from './guards/superadmin.guard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './views/extrapages/page404/page404.component';
const admin: Routes = [
  
  { path: 'account', loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule) },
  { path: '', component: LayoutComponent, loadChildren: () => import('./views/globals/globals.module').then(m => m.GlobalsModule), canActivate: [AuthGuard] },
 
  { path: 'setting', component: LayoutComponent, loadChildren: () => import('./views/setting/setting.module').then(m => m.SettingModule), canActivate: [AuthGuard] },
 
  { path: 'customer', component: LayoutComponent, loadChildren: () => import('./views/customer/customer.module').then(m => m.CustomerModule), canActivate: [AuthGuard] },
  { path: '',   redirectTo: '/', pathMatch: 'full' }, // redir
  

];

const superAdmin:Routes=[
  { path: 'bod', component: LayoutComponent, loadChildren: () => import('./views/internal/internal.module').then(m => m.InternalModule), canActivate: [SuperadminGuard] },

]


const routes:any=[
  ...admin,
  ...superAdmin,
   { path: '**', component: Page404Component },

]


@NgModule({

  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
