import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  hoveredDate: NgbDate | null = null;

  @Input() startDate : Date;
  @Input() endDate : Date;
  @Output() sendDate:EventEmitter<any>= new EventEmitter();
  sd:Date;
  ed:Date;

  fromDate: NgbDate;
  toDate: NgbDate;
X
  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    
  }

  ngOnInit(): void {
    this.sd = new Date(this.startDate)??null;
    this.ed = new Date(this.endDate)??null;
      if( this.sd && this.ed){
          this.fromDate = new NgbDate(this.sd.getFullYear(), this.sd.getMonth()+1, this.sd.getDate());
          this.toDate = new NgbDate(this.ed.getFullYear(), this.ed.getMonth()+1,this.ed.getDate());
          
      }else{

          this.fromDate = this.calendar.getToday();
          this.toDate = this.calendar.getNext(this.calendar.getToday(), 'm', 1);
      }

      
  }

  onDateSelection(date: NgbDate) {
    
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) &&
        date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) { return this.toDate && date.after(this.fromDate) && date.before(this.toDate); }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) ||
        this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
   
  setDate(){
    this.sendDate.emit({
      fromDate:new Date(this.fromDate.year,this.fromDate.month-1,this.fromDate.day) ,
      endDate:new Date(this.toDate.year,this.toDate.month-1,this.toDate.day) ,
    });
  }


}
